const animateRoot = document.querySelector('.guide-animation')
document.querySelector('.guide-animation__section3')
  .addEventListener('animationend', ({ animationName }) => {
  if (animationName === 'gaAnime__FadeOut') {
    animateRoot.setAttribute('data-state', 'false')
    setTimeout(() => {
      animateRoot.setAttribute('data-state', 'true')
    }, 100)
  }
})
